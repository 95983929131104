<template>
  <div>
    <ch-layout page search button>
      <template #button>
        <ch-button @click="openAdd" style="margin-right: 10px">新增</ch-button>
        <ch-button @click="openTags" style="margin-right: 10px">标签库</ch-button>
        <ch-button @click="openCode" style="margin-right: 10px">二维码</ch-button>
        <ch-button @click="openTemporary">临时服务人员</ch-button>
      </template>
      <template #search>
        <div style="width: 100%;">
          <ch-search
              :render-option="searchOption" :model="searchForm"
              :props="{ labelWidth: '66px'}">
          </ch-search>
        </div>
      </template>

      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:available="scope">
            <div v-if="scope.row.isAvailable===1" class="circle circle_green"></div>
            <div v-else class="circle circle_red"></div>
          </template>
          <template v-slot:handle="scope">
            <ch-button type="link" @click="openEdit(scope.row)">编辑</ch-button>
            <ch-button type="delete" v-if="scope.row.status===2" @click="toExamine(scope.row)">审核</ch-button>
            <ch-button type="link" v-if="scope.row.status===0" @click="handleRecoverStop(scope.row)">停用</ch-button>
            <ch-button type="link" v-if="scope.row.status===1" @click="handleRecoverStop(scope.row)">恢复</ch-button>
            <ch-button type="delete" v-if="scope.row.canDelete===1" @click="handleDelete(scope.row)">删除</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page" :sizeDe="20"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <!-- 新增 编辑 -->
    <modal ref="modal" :get-list-function="getTableList"/>
    <!-- 标签库 -->
    <tags-list ref="tagsList"/>
    <!-- 二维码 -->
    <qr-code-modal ref="qrCodeModal"/>
    <temporary ref="temporary"></temporary>
  </div>
</template>

<script>
import Modal from "./modal";
import { handleDelete } from "@/utils/utils";
const tagsList = () => import("./tagsList");
const qrCodeModal = () => import("./qrCodeModal");
const temporary = () => import("./temporary/index.vue")
export default {
  components:{Modal,tagsList,qrCodeModal,temporary},
  data(){
    return{
      // 搜索相关
      searchForm: {
        status:null,
        name:null,
      },
      tableList: [], // table列表
      isTableLoading: false, // table加载
      disabled: true,
      communityList:[],
      communityGroup:[],
      typeOption:[{
        name:'家政',
        id:1,
      }],
      selectedOptions: [],
      classifyOption:[],
      statusList:['正常','停用','审核','不同过'],
    }
  },
  computed:{
    searchOption: function (){
      return [
        {type: 'input', label: '服务人员', prop: 'name', placeholder: "请输入",},
        {type: 'input', label: '手机号', prop: 'phone', placeholder: "请输入",},
        {type: "select", label: "服务类型", prop: "classifyIds", placeholder: "请选择",
          name: "name", value: "id", option: this.classifyOption, props: {multiple: true, collapseTags: true} },
        {type: "select", label: "状态", prop: "status", placeholder: "请选择",
          name: "label", value: "value", option: [
            { value: 0, label: "正常" }, { value: 1, label: "停用" },
            { value: 2, label: "审核" },{ value: 3, label: "不同过" },
          ]},
        {type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.resetSearch},
          ]},

      ]
    },
    tableOption:function (){
      return [
        { column: "type", prop: "", type: "selection", width: "25px" },
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "服务人员", prop: "name", showOverflowTooltip: true,},
        { column: "text", label: "手机号", prop: "phone", showOverflowTooltip: true,},
        { column: "text", label: "服务类型", prop: "classify", showOverflowTooltip: true,},
        { column: "text", label: "性别", prop: "_gender", showOverflowTooltip: true,},
        { column: "text", label: "分佣比例%", prop: "commissionRate", showOverflowTooltip: true,},
        { column: "text", label: "评价星级", prop: "num", showOverflowTooltip: true,},
        { column: "text", label: "用户评价", prop: "num", showOverflowTooltip: true,},
        { column: "text", label: "累计服务次数", prop: "num", showOverflowTooltip: true,},
        { column: "text", label: "状态", prop: "_status", showOverflowTooltip: true,},
        { column: "slot", label: "服务状态", slotName: "available"},
        { column: "slot", label: "操作", slotName: "handle", width: "140" },
      ];
    },
  },
  mounted() {
    this.getTableList()
  },
  methods:{
    // 查询
    searchHandle() {
      this.getTableList()
    },
    resetSearch() {
      this.getTableList({})
    },
    async getClassify(){
       await this.$curl.get('/hm/classify/secondList').then(res=>{
        this.classifyOption = res.data
      })
    },
    // 列表数据
    async getTableList(params=this.searchForm) {
      await this.getClassify()
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/serviceStaff/getList",params).then((res) => {
        let row = res.rows
        this.tableList = row.map((item) => {
          let classify = this.classifyOption.filter(cl => item.classifyIds.includes(cl.id)).map(v=>v.name).join('/')
          return {
            ...item,
            _status:this.statusList[item.status],
            classify:classify,
            _gender:item.gender===0||item.gender===null?'一':item.gender===1?'男':'女',
          }
        })
        this.isTableLoading = false
      }).catch(() => {this.isTableLoading = false});
    },

    // 新增
    openAdd(){
      this.$refs.modal.openAdd()
    },
    //审核
    toExamine(row) {
      this.$confirm("是否通过审核?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$curl.get(`/hm/serviceStaff/audit/${row.id}/${row.status}`).then(() => {
          this.$message.success("审核通过！");
          this.getTableList()
        })
      })
    },
    // 编辑
    openEdit(row){
      this.$refs.modal.openEdit(row)
    },
    // 恢复停用
    handleRecoverStop(row) {
      let text = row.status===0? '停服':'恢复'
      this.$confirm(`${text}本服务人员, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let status = row.status===0? 1:0
        this.$curl.post(`/hm/serviceStaff/updateStatus/${row.id}/${status}`)
            .then(() => {
              this.$message.success("操作成功！");
              this.getTableList()
        })
      })
    },
    // 删除
    handleDelete(row) {
      handleDelete("/hm/serviceStaff/del/", row.id).then(() => {
        this.getTableList();
      });
    },
    // 标签库
    openTags(){
      this.$refs.tagsList.openTagsList()
    },
    // 二维码
    openCode(){
      this.$refs.qrCodeModal.openCode()
    },
    openTemporary() {
      this.$refs.temporary.open()
    },
  }
}
</script>

<style scoped lang="scss">
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 20px;
}
.circle_green{
  background: #00ff00;
}
.circle_red{
  background: #ff0000;
}
</style>